<template>
  <div>
    <!-- search input -->
    <section id="knowledge-base-search">
      <b-card
        no-body
        class="knowledge-base-bg text-center"
        :style="{backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="card-body">
          <h1 class="text-primary">
            SALOMHAYOT CRM'ga Xush Kelibsiz!
          </h1>
        </b-card-body>
      </b-card>
    </section>
    <h2 class="text-center mb-2">
      Bizing tizim orqali siz biznesingizga quyidagi imkoniyatlarni qo'shishingiz mumkin
    </h2>

    <section id="knowledge-base-content">

      <!-- content -->
      <b-row class="kb-search-content-info match-height">
        <b-col
          v-for="item in filteredKB"
          :key="item.id"
          md="4"
          sm="6"
          class="kb-search-content"
        >
          <b-card
            class="text-center cursor-pointer"
            :img-src="item.img"
            :img-alt="item.img.slice(5)"
            img-top
          >
            <h4>{{ item.title }}</h4>
            <b-card-text class="mt-1">
              {{ item.desc }}
            </b-card-text>
          </b-card>
        </b-col>
        <b-col
          v-show="!filteredKB.length"
          cols="12"
          class="text-center"
        >
          <h4 class="mt-4">
            Search result not found!!
          </h4>
        </b-col>
      </b-row>
    </section>

    <div
      id="contact-us"
      class="card p-5"
    >
      <h2
        class="mb-4 text-center"
      >
        Biz bilan bog'laning
      </h2>
      <div class="d-flex flex-column text-center">
        <div class="d-block  contact-info">
          <feather-icon
            icon="PhoneIcon"
            size="12"
          />
          +998 97 860 11 11
        </div>
        <div class="d-block mt-2 contact-info">

          <feather-icon
            icon="MailIcon"
            size="12"
          />
          walmartworldwide@mail.ru
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
  },
  data() {
    return {
      knowledgeBaseSearchQuery: '',
      kb: [],

    }
  },
  computed: {
    filteredKB() {
      const knowledgeBaseSearchQueryLower = this.knowledgeBaseSearchQuery.toLowerCase()
      return this.kb.filter(item => item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower) || item.desc.toLowerCase().includes(knowledgeBaseSearchQueryLower))
    },
  },
  created() {
    this.$http.get('/kb/data/knowledge_base').then(res => { this.kb = res.data })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
.contact-info{
  font-size: 24px;
        line-height: 32px;
        font: inherit;
        color: #000;
        font-weight: 800;
}
</style>
